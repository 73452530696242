import { PaletteMode } from '@mui/material';

import { HOME_ROUTE } from '../routes';

export enum TenantsRefs {
  Fu = 'financeunlocked',
  Su = 'sustainabilityunlocked',
  Em = 'euromoney',
  Dsu = 'datascienceunlocked',
  Xunl = 'xunlocked',
  Bpp = 'bpp',
  Dukece = 'dukece',
  Ssku = 'skills',
}

export enum TenantsUrls {
  Fu = 'https://financeunlocked.com',
  Su = 'https://sustainabilityunlocked.com',
  Em = 'https://ondemand.euromoney.com',
  Dsu = 'https://data-scienceunlocked.com',
  Xunl = 'https://xunlocked.com',
  Bpp = 'https://sustainability.bpp.com',
  Dukece = 'https://sustainability.dukece.com',
  Ssku = 'https://skills.xunlocked.com',
}

export const TenantsUrlsByNames = Object.keys(TenantsRefs).reduce(
  (acc, key) => ({ ...acc, [TenantsRefs[key]]: TenantsUrls[key] }),
  {},
);

const resetPasswordUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`;
const logoutUrl = '/';

const getDashboardHeroImages = () => ({
  manager: '/headers-images/manager_dashboard.png',
  learner: '/headers-images/dashboard.png',
});

interface IAuth {
  logoutUrl: string;
  connection?: string;
}

const auth: IAuth = {
  logoutUrl,
};

export const TENANTS_CONFIG = {
  [TenantsRefs.Fu]: {
    apiUrl: process.env.REACT_APP_API_FU_URL,
    logo: '/Finance-unlocked-logo.png',
    surveyLink: 'https://039vtsgfa1a.typeform.com/to/TWWh25Y9',
    pathwaySeoDescKey: 'fuSEODescription',
    videoShareTextKey: 'videoShareFu',
    pathwayCertShareDefaultTextKey: 'pathwayShareFu',
    pricingPageSeoDescKey: 'description',
    glossarySeoImg: '/page-images/glossarySeoImg.png',
    hasInsights: true,
    canHaveSustainableGoal: false,
    hasConsentCheck: true,
    courseCertificateImgUrl: '/page-images/FU_Certificate.png',
    joinNowBlock: {
      titleKey: 'subscribeToWatch',
      descriptionKey: 'subscribeToWatchDesc',
      link: '/pricing',
      btnCaptionKey: 'startFreeTrial',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescFu',
    },
    langSwitchingAllowed: false,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl,
    getDashboardHeroImages,
    auth,
  },
  [TenantsRefs.Su]: {
    apiUrl: process.env.REACT_APP_API_SU_URL,
    logo: 'sustainabilityunlocked.png',
    surveyLink: 'https://039vtsgfa1a.typeform.com/to/tfy5CjoO',
    pathwaySeoDescKey: 'commonSEODescription',
    videoShareTextKey: 'videoShareCommon',
    pathwayCertShareDefaultTextKey: 'pathwayShareCommon',
    pricingPageSeoDescKey: 'descriptionSu',
    glossarySeoImg: '/page-images/glossarySeoImgSU.png',
    hasInsights: true,
    canHaveSustainableGoal: true,
    hasConsentCheck: true,
    courseCertificateImgUrl: '/page-images/SU_Certificate.png',
    joinNowBlock: {
      titleKey: 'subscribeToWatch',
      descriptionKey: 'subscribeToWatchDesc',
      link: '/pricing',
      btnCaptionKey: 'startFreeTrial',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescFu',
    },
    langSwitchingAllowed: false,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl,
    getDashboardHeroImages,
    auth,
  },
  [TenantsRefs.Em]: {
    apiUrl: process.env.REACT_APP_API_ELOD_URL,
    logo: '/Euromoney-logo.png',
    pathwaySeoDescKey: 'fuSEODescription',
    videoShareTextKey: 'videoShareCommon',
    pathwayCertShareDefaultTextKey: 'pathwayShareCommon',
    pricingPageSeoDescKey: 'description',
    glossarySeoImg: '/page-images/glossarySeoImgELOD.png',
    hasInsights: false,
    canHaveSustainableGoal: false,
    hasConsentCheck: true,
    courseCertificateImgUrl: '',
    joinNowBlock: {
      titleKey: 'speakToExpert',
      descriptionKey: 'speakToExpertDesc',
      link: '/contact',
      btnCaptionKey: 'getInTouch',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescElod',
    },
    langSwitchingAllowed: false,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl,
    getDashboardHeroImages,
    auth,
  },
  [TenantsRefs.Dsu]: {
    apiUrl: process.env.REACT_APP_API_DSU_URL,
    logo: 'data-scienceunlocked-logo.webp',
    //TODO add survey link after creation
    surveyLink: '',
    pathwaySeoDescKey: 'commonSEODescription',
    videoShareTextKey: 'videoShareCommon',
    pathwayCertShareDefaultTextKey: 'pathwayShareCommon',
    pricingPageSeoDescKey: 'descriptionDsu',
    hasInsights: false,
    canHaveSustainableGoal: false,
    hasConsentCheck: true,
    courseCertificateImgUrl: '',
    joinNowBlock: {
      titleKey: 'subscribeToWatch',
      descriptionKey: 'subscribeToWatchDesc',
      link: '/pricing',
      btnCaptionKey: 'startFreeTrial',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescFu',
    },
    langSwitchingAllowed: false,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl,
    getDashboardHeroImages,
    auth,
  },
  [TenantsRefs.Xunl]: {
    apiUrl: process.env.REACT_APP_API_XUNL_URL,
    logo: 'xunlocked.png',
    pathwaySeoDescKey: 'commonSEODescription',
    videoShareTextKey: 'videoShareCommon',
    pathwayCertShareDefaultTextKey: 'pathwayShareCommon',
    hasInsights: true,
    canHaveSustainableGoal: false,
    newsSeoImg: '/page-images/newsSeoImgXUNL.png',
    hasConsentCheck: true,
    courseCertificateImgUrl: '',
    joinNowBlock: {
      titleKey: 'subscribeToWatch',
      descriptionKey: 'subscribeToWatchDesc',
      link: '/contact',
      btnCaptionKey: 'startFreeTrial',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescFu',
    },
    langSwitchingAllowed: false,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl,
    getDashboardHeroImages,
    auth,
  },
  [TenantsRefs.Bpp]: {
    apiUrl: process.env.REACT_APP_API_BPP_URL,
    logo: 'BPP-SU.png',
    pathwaySeoDescKey: 'commonSEODescription',
    videoShareTextKey: 'videoShareCommon',
    pathwayCertShareDefaultTextKey: 'pathwayShareCommon',
    pricingPageSeoDescKey: 'descriptionSu',
    glossarySeoImg: '/page-images/glossarySeoImgBPP.png',
    hasInsights: false,
    canHaveSustainableGoal: true,
    hasConsentCheck: true,
    courseCertificateImgUrl: '',
    joinNowBlock: {
      titleKey: 'speakToExpert',
      descriptionKey: 'speakToExpertDesc',
      link: '/contact',
      btnCaptionKey: 'getInTouch',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescElod',
    },
    langSwitchingAllowed: false,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl,
    getDashboardHeroImages,
    auth,
  },
  [TenantsRefs.Dukece]: {
    apiUrl: process.env.REACT_APP_API_DUKECE_URL,
    logo: 'DUKE-SU.png',
    pathwaySeoDescKey: 'commonSEODescription',
    videoShareTextKey: 'videoShareCommon',
    pathwayCertShareDefaultTextKey: 'pathwayShareCommon',
    pricingPageSeoDescKey: 'descriptionSu',
    hasInsights: false,
    canHaveSustainableGoal: true,
    hasConsentCheck: true,
    courseCertificateImgUrl: '',
    joinNowBlock: {
      titleKey: 'speakToExpert',
      descriptionKey: 'speakToExpertDesc',
      link: '/contact',
      btnCaptionKey: 'getInTouch',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescElod',
    },
    langSwitchingAllowed: false,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl,
    getDashboardHeroImages,
    auth,
  },
  [TenantsRefs.Ssku]: {
    apiUrl: process.env.REACT_APP_API_SSKU_URL,
    logo: 'SSKU.png',
    //TODO add survey link after creation
    pathwaySeoDescKey: 'commonSEODescription',
    videoShareTextKey: 'videoShareSsku',
    pathwayCertShareDefaultTextKey: 'pathwayShareCommon',
    hasInsights: false,
    //TODO add glossary SEO img when it will be ready
    hasConsentCheck: false,
    courseCertificateImgUrl: '/page-images/Skills_certificate.png',
    joinNowBlock: {
      titleKey: 'speakToExpert',
      descriptionKey: 'haveAQuestion',
      link: '/login',
      btnCaptionKey: 'getInTouch',
      freeVideoTitleKey: 'nowFreeToWatch',
      freeVideoDescriptionKey: 'nowFreeToWatchDescElod',
    },
    langSwitchingAllowed: true,
    appMainPage: HOME_ROUTE,
    resetPasswordUrl:
      'https://sso.santanderopenacademy.com/auth/realms/Becas/login-actions/reset-credentials?client_id=becas&tab_id=Vml28FxrDP0&kc_locale=en',
    getDashboardHeroImages: (mode: PaletteMode) =>
      mode === 'dark'
        ? {
            manager: '/headers-images/skillsunlocked/3.png',
            learner: '/headers-images/skillsunlocked/3.png',
          }
        : {
            manager: '/headers-images/skillsunlocked/7.png',
            learner: '/headers-images/skillsunlocked/7.png',
          },
    auth: {
      logoutUrl: 'https://www.santanderopenacademy.com/en/index.html',
      connection: 'OpenAcademy',
    },
  },
};
export const getHeaderLogoSsku = (theme) =>
  `https://img-cdn.financeunlocked.com/logos/open_academy_${theme}.svg`;

const SU_HEADER_URLS = [
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_1.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_2.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_3.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_4.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_5.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_6.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_7.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_8.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_9.jpg',
  'https://img-cdn.financeunlocked.com/headers-images/sustainabilityunlocked/SU_backgrounds_10.jpg',
];
const SSKU_HEADER_URLS_DARK = [
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/1.png',
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/2.png',
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/3.png',
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/4.png',
];
const SSKU_HEADER_URLS_LIGHT = [
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/5.png',
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/6.png',
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/7.png',
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/8.png',
  'https://img-cdn.financeunlocked.com/headers-images/skillsunlocked/9.png',
];
export const getHeroImageSet = (mode?: PaletteMode) => ({
  [TenantsRefs.Em]: [
    'https://img-cdn.financeunlocked.com/headers-images/euromoney/1.jpg',
    'https://img-cdn.financeunlocked.com/headers-images/euromoney/2.jpg',
    'https://img-cdn.financeunlocked.com/headers-images/euromoney/3.jpg',
    'https://img-cdn.financeunlocked.com/headers-images/euromoney/4.jpg',
  ],
  [TenantsRefs.Fu]: [
    'https://img-cdn.financeunlocked.com/headers-images/financeunlocked/1.jpg',
    'https://img-cdn.financeunlocked.com/headers-images/financeunlocked/2.jpg',
    'https://img-cdn.financeunlocked.com/headers-images/financeunlocked/3.jpg',
    'https://img-cdn.financeunlocked.com/headers-images/financeunlocked/4.jpg',
  ],
  [TenantsRefs.Su]: SU_HEADER_URLS,
  [TenantsRefs.Bpp]: SU_HEADER_URLS,
  [TenantsRefs.Dukece]: SU_HEADER_URLS,
  [TenantsRefs.Ssku]: mode === 'dark' ? SSKU_HEADER_URLS_DARK : SSKU_HEADER_URLS_LIGHT,
});

const tenantsRefsWithoutInsights = Object.entries(TENANTS_CONFIG)
  .filter(([, config]) => !config.hasInsights)
  .map(([tenantRef]) => tenantRef);

export const checkIfHasInsights = (host) => {
  return !tenantsRefsWithoutInsights.some((tenantRef) => host?.includes(tenantRef));
};
