import { hostToTenantName } from './hostToTenantName/hostToTenantName';
import { TenantsRefs } from './tenantsConfig';

export enum UserComEvents {
  gtmEvent = 'gtm-event',
  newSession = 'new_session',
  openFirstAslVideo = 'open_first_asl_video',
  pathwayStarted = 'pathway_started',
  pathwayReviewSubmitted = 'pathway_review_submitted',
  pathwayTestSuccessfullyCompleted = 'pathway_test_successfully_completed',
}

class UserCom {
  static checkHasUserCom() {
    return Boolean(window?.userengage);
  }

  static openWidget() {
    if (window?.userengage) {
      return window.userengage('widget.open');
    }
  }

  static showWidget() {
    if (window?.userengage) {
      return window.userengage('widget.show');
    }
  }

  static hideWidget() {
    if (window?.userengage) {
      return window.userengage('widget.hide');
    }
  }

  static updateClient(data: { [key: string]: any }) {
    if (window?.userengage) {
      return window.userengage('client.update', {
        ...data,
      });
    }
  }

  static sendEvent(eventName: string, data?: { [key: string]: any }) {
    if (window?.userengage) {
      return window.userengage(`event.${eventName}`, {
        ...data,
      });
    }
  }

  static checkHasPageHit() {
    return Boolean(window?.UE?.pageHit);
  }

  static pageHit(data: { [key: string]: any }) {
    if (window?.UE?.pageHit) {
      return window.UE.pageHit({
        apiKey:
          hostToTenantName(window.location?.hostname) === TenantsRefs.Ssku
            ? process.env.REACT_APP_USERCOM_SSKU_API_KEY
            : process.env.REACT_APP_USERCOM_API_KEY,
        ...data,
      });
    }
  }

  static resetAuth() {
    if (window?.UE?.resetAuth) {
      return window.UE.resetAuth({
        apiKey:
          hostToTenantName(window.location?.hostname) === TenantsRefs.Ssku
            ? process.env.REACT_APP_USERCOM_SSKU_API_KEY
            : process.env.REACT_APP_USERCOM_API_KEY,
      });
    }
  }

  static destroyWidget() {
    if (window?.UE?.destroy) {
      return window.UE.destroy();
    }
  }
}

export default UserCom;
